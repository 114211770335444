import React from "react";
import "./optimizertable.css";
import DataNotFound from "../../DataNotFound/DataNotFound";
import ShimmerTable from "../../Tables/ShimmerTable";
import { useState } from "react";
import { useRef } from "react";
import LazyLoadSpinner from "../../spinners/LazyLoadSpinner";
import { useEffect } from "react";

const OptimizerTable = ({
  tableHeaders,
  resultSet,
  tableclassName,
  className,
  customClasses,
  renderSummaryRow,
  renderTableHeader,
  isSummaryRowRequired,
  renderTableRow,
  totalSummary,
  renderComparisonFirstRow,
  isComparisonView,
  tableLoading,
  childRowsLoading,
  isExpandable,
  getData,
  recordLimit,
  recieveLoadedData,
  resetOffset,
  noDataFound,
  mainTableClass,
  rowsCount,
  handleEditableCellClick,
  updateEditableCell,
  handleEditableValueChange
}) => {
  const [loading, setLoading] = useState(false);
  const [startIdx,setStartIdx] = useState(0);
  const visibleRows = resultSet?.slice(0,startIdx)
  const mainRef = useRef(null);
  const pageSize = 30;
  const currentRowCount = rowsCount || 0;
  const [currentPage, setCurrentPage] = useState(0);  

    
  useEffect(()=> {
    if(visibleRows.length === 0) {
      if(resultSet.length > 0) {
        loadMoreData();
      }
    }
  }, [resultSet])

  const handleScroll = async (e) => {
    if (mainRef.current) {
      const { scrollTop, clientHeight, scrollHeight } = mainRef.current;
      const container = mainRef.current;
      // Keep track of last scroll position
      const lastScrollTop = container.getAttribute("data-scroll-top") || 0;
      // Determine scroll direction
      const scrollDirection = container.scrollTop > lastScrollTop ? "down" : "up";
      // Update last scroll position
      container.setAttribute("data-scroll-top", container.scrollTop);
      if(scrollDirection === "down" ) {
        if(scrollTop===0) {
          if (startIdx >= pageSize) {
            setStartIdx(startIdx - pageSize);
          }
        } else if(scrollTop + clientHeight >= scrollHeight - 100) {
          if(startIdx < resultSet?.length) {
            loadMoreData();
          } else if (startIdx + pageSize >= resultSet.length && resultSet.length < currentRowCount) {
            if ((Math.abs(scrollHeight - clientHeight - scrollTop) <= 1) && (scrollDirection==="down") && (loading!==true )) {
              const nextPage = currentPage + 1;
              const nextOffset = nextPage * recordLimit;
              setLoading(true);
              if (!loading) {
                const dataForMoreData = {
                  page: nextPage,
                  offset: nextOffset,
                };
                const mainData = await getData(dataForMoreData);
                setLoading(false);
                recieveLoadedData(mainData);
                setCurrentPage(nextPage);
              }
            }
          }
        }
     
      }
    
    }
  };



  useEffect(() => {
    setCurrentPage(0);
    setStartIdx(0);
  },[resetOffset])

  function getFreezeColumnClassName (keyName) {
    switch (keyName) {
      case "targeting":
       return "targeting-columns"
      default:
        break;
    }
  }

  const loadMoreData = () => {
    // const newData = resultSet.slice(startIdx, startIdx + pageSize);
    // setVisibleRows([...visibleRows, ...newData]);
    setStartIdx(startIdx + pageSize);
  };

  
  return (
    <div className="optimizer-main-table">
      <div
        className={
          className
            ? `main-tableRb-body  position-relative d-flex flex-column` +
              className
            : "main-tableRb-body position-relative d-flex flex-column"
        }
      >
        <div
          className={
            "tableRb text-center" + (tableclassName ? tableclassName : "")
          }
        >
          <div
            className={
              "main-campaignlist-table-body main-targeting-table-body-container listinng-table comparison-view-wrapper" + (mainTableClass ? mainTableClass : "")
            }
            ref={mainRef}
            onScroll={handleScroll}
          >
            {
              <>
                {tableLoading ? (
                  <ShimmerTable
                    rows={20}
                    tableHeaders={tableHeaders}
                    hideHeaders={false}
                  />
                ) : noDataFound ? (
                  <DataNotFound />
                ) : (
                  <>
                    <table className={`optimizer-table targeting-columns`}>
                      <thead className="fixed-table">
                        <tr className="main-table-row-fixed">
                          {tableHeaders?.map((data) => {
                            return renderTableHeader(data, isComparisonView);
                          })}
                        </tr>
                      </thead>
                      {resultSet?.length ? (
                        <tbody className="another-tbody">
                          {isSummaryRowRequired
                            ? renderSummaryRow(totalSummary)
                            : null}
                          {isComparisonView ? renderComparisonFirstRow() : null}
                          {visibleRows?.map((result, index) => {
                            return renderTableRow(
                              result,
                              index,
                              childRowsLoading,
                              isExpandable
                            );
                          })}
                        </tbody>
                      ) : (
                        <DataNotFound />
                      )}
                    </table>
                    {loading && (
                      <div className="lazy-loading-container d-flex p-2 justify-content-center align-items-center w-100">
                        <LazyLoadSpinner
                          text={`Loading ${recordLimit} More Items...`}
                        />
                      </div>
                    )}
                  </>
                )}
              </>
            }
          </div>
        </div>
      </div>
      </div>
  );
};

export default OptimizerTable;
