import React, { useCallback, useEffect, useState } from "react";
import SectionTitle from "../../components/sectionTitle";
import { useSelector } from "react-redux";
import { Button, Card, Form,ProgressBar } from "react-bootstrap";
import CustomDropDown from "../../components/DropDown/CustomDropDown";
import { Scatter } from "react-chartjs-2";
import {
    Chart,
    LinearScale,
    CategoryScale,
    PointElement,
    Tooltip,
    LineElement,
    Filler,
    BarElement,
    Legend,
    LineController,
    BarController,
  } from "chart.js";
import { createQuadrants,generateSummaryData,getAllQuadrantFilters,metricsMapper,validateInputData,generateContent, createImage ,initialFilterConfigs } from "../../helper/Quadrant/quadrantHelper";
import Annotation from "chartjs-plugin-annotation";
import cubejs from "@cubejs-client/core";
import { useGlobalContext } from "../../context/global";
import { formatDate } from "../../components/Reports/Components/utils";
import {toast} from 'react-toastify'
import useToken from "../../helper/Reports/useToken";
import topPerformingImage from '../../assets/svgs/202.svg';
import lowDiscoveryImage from '../../assets/svgs/questionmark.svg';
import nonPerformingImage from '../../assets/svgs/nonperforming.svg';
import highPotentialImage from '../../assets/svgs/topstar.svg';
import FiltersModal from "../../components/modal/FiltersModal";
import MultiSelect from "../../components/DropDown/Multiselect";
import OptimizerFilters from "../../components/optimizer/OptimizerFilters";
import downArrowImage from '../../assets/svgs/downArrow.svg';
  import './quadrantview.css';
import PageStatus from "../../components/Common/PageStatus";


const QuadrantView = () => {
  const dashboardSideBarVisibiltyState = useSelector(
    (state) => state.dashboardData.dashboardSideBarVisibiltyState
  );
  const quadrants = createQuadrants();
  const {cubeToken,setCubeToken,calendarDate,clientAccountId} = useGlobalContext()

  const [conditionalFilters,setConditionalFilters] = useState([{id:1,filterName:'',value:''},{id:2,filterName:'',value:''}])

  
  const [modalVisible,setModalVisible] = useState(false)

  const [cubeQueryMeasure, setCubeQueryMeasure] = useState([
    "VariantAnalysisTable.cost",
    "VariantAnalysisTable.costDRR",
    "VariantAnalysisTable.impressions",
    "VariantAnalysisTable.impressionsDRR",
    "VariantAnalysisTable.clicks",
    "VariantAnalysisTable.clicksDRR",
    "VariantAnalysisTable.ctr",
    "VariantAnalysisTable.ctrDRR",
    "VariantAnalysisTable.cpc",
    "VariantAnalysisTable.cpcDRR",
    "VariantAnalysisTable.cpm",
    "VariantAnalysisTable.cpmDRR",
    "VariantAnalysisTable.conversions14d",
    "VariantAnalysisTable.conversions14dDRR",
    "VariantAnalysisTable.conversion_rate",
    "VariantAnalysisTable.conversion_rateDRR",
    "VariantAnalysisTable.sales14d",
    "VariantAnalysisTable.sales14dDRR",
    "VariantAnalysisTable.average_order_value",
    "VariantAnalysisTable.average_order_valueDRR",
    "VariantAnalysisTable.acos",
    "VariantAnalysisTable.acosDRR",
    "VariantAnalysisTable.roas",
    "VariantAnalysisTable.roasDRR",
  ]);

  
  const yAxisStartValue = Number(conditionalFilters[1].value ?? 0);
  const xAxisStartValue = Number(conditionalFilters[0].value ?? 0);
  const [filters,setFilters] = useState(initialFilterConfigs)


  const paramsForQuadrantFilters = {
    xFilterName:conditionalFilters[0]?.filterName,
    xFiltervalue:xAxisStartValue,
    yFilterName:conditionalFilters[1]?.filterName,
    yFilterValue:yAxisStartValue,
  }

  const {quadrant1Filter,quadrant2Filter,quadrant3Filter,quadrant4Filter} = getAllQuadrantFilters(paramsForQuadrantFilters);

  const getAdditionalFilters = (data) => {
    let filters = []
     data.forEach((filter) => {
      if(filter?.default?.length > 0) {
        filters.push({member:`VariantAnalysisTable.${filter?.memberkey}`,operator:'equals',values:filter?.default})
      }
    });
    return filters
  }

  const additonalFilters = getAdditionalFilters(filters)


  Chart.register(CategoryScale, LinearScale, PointElement, quadrants, Tooltip , LineElement , Filler , Annotation , BarElement , Legend , BarController );

  const YAxisMetrics = ["ROAS", "Orders", "Revenue", "Conv. Rate"];

  const XAxisMetrics = ["Spends", "Clicks", "Impressions"];

  const additionalMetrics = ["CTR","CPM","ACOS","CPC",'AOV','Revenue','Conv. Rate']

  const cubejsApi = cubejs(cubeToken, {
    apiUrl: process.env.REACT_APP_CUBE_JS_URL,
  });

  const [selectedYAxisMetric, setSelectedYAxisMetric] = useState("ACOS");
  const [additionalMetric,setAdditionalMetric] = useState("CTR") 
  const [selectedXAxisMetric, setSelectedXAxisMetric] = useState("");
  const [loading,setIsLoading] = useState(false);
  const [completion,setCompletion] = useState(0)

  const [cubeQueryOrder, setCubeQueryOrder] = useState([
    ["VariantAnalysisTable.cost", "desc"],
    ["VariantAnalysisTable.parentSku", "desc"],
  ]); 

  


  // All Summary Related states
  const [q1Summary,setQ1Summary] = useState({summary:{},data:[]});
  const [q2Summary,setQ2Summary] = useState({summary:{},data:[]});
  const [q3Summary,setQ3Summary] = useState({summary:{},data:[]});
  const [q4Summary,setQ4Summary] = useState({summary:{},data:[]});

  const [token, refresh] = useToken("");


  const [fetchIssue,setFetchIssue] = useState(false)
  const yAxisFilter = conditionalFilters[1]?.filterName
  const xAxisFilter = conditionalFilters[0]?.filterName
  const yAxisMetricSelected =
    yAxisFilter === "" ? "Select" : yAxisFilter;
  const xAxisMetricSelected =
    xAxisFilter === "" ? "Select" : xAxisFilter;

  const xKeyFilter = metricsMapper[xAxisMetricSelected]
  const yKeyFilter = metricsMapper[yAxisMetricSelected]

  const additionalFilterMetric = metricsMapper[additionalMetric]

  const additionalFilterMetric1 = q1Summary?.summary[additionalFilterMetric] ?? 0;
  const additionalFilterMetric2 = q2Summary?.summary[additionalFilterMetric] ?? 0;
  const additionalFilterMetric3 = q3Summary?.summary[additionalFilterMetric] ?? 0;
  const additionalFilterMetric4 = q4Summary?.summary[additionalFilterMetric] ?? 0;

 
  
  useEffect(() => {
  if(validateInputData(conditionalFilters)){
      handleQuadrantDataUpdate()
    }
  },[filters])


  const quadrant1MetricX = q1Summary?.summary[xKeyFilter] ?? 0
  const quadrant1MetricY = q1Summary?.summary[yKeyFilter] ?? 0
  const quadrant2MetricX = q2Summary?.summary[xKeyFilter] ?? 0
  const quadrant2MetricY = q2Summary?.summary[yKeyFilter] ?? 0
  const quadrant3MetricX = q3Summary?.summary[xKeyFilter] ?? 0
  const quadrant3MetricY = q3Summary?.summary[yKeyFilter] ?? 0
  const quadrant4MetricX = q4Summary?.summary[xKeyFilter] ?? 0
  const quadrant4MetricY = q4Summary?.summary[yKeyFilter] ?? 0

  

  const  q1Content = generateContent(q1Summary?.summary['total_count'],xAxisFilter,quadrant1MetricX,yAxisFilter,quadrant1MetricY,additionalMetric,additionalFilterMetric1)
  const  q2Content = generateContent(q2Summary?.summary['total_count'],xAxisFilter,quadrant2MetricX,yAxisFilter,quadrant2MetricY,additionalMetric,additionalFilterMetric2)
  const  q3Content = generateContent(q3Summary?.summary['total_count'],xAxisFilter,quadrant3MetricX,yAxisFilter,quadrant3MetricY,additionalMetric,additionalFilterMetric3)
  const  q4Content = generateContent(q4Summary?.summary['total_count'],xAxisFilter,quadrant4MetricX,yAxisFilter,quadrant4MetricY,additionalMetric,additionalFilterMetric4)

  const highPotential = createImage(highPotentialImage);
  const lowDiscovery = createImage(lowDiscoveryImage);
  const nonperforming = createImage(nonPerformingImage);
  const topperforming = createImage(topPerformingImage);

  const negativeIconScaleX = (window.innerWidth > 1200 ) ? -2 : (mobileDevice && window.innerWidth>650) ? -2 : (window.innerWidth < 650 && window.innerWidth>440) ? -2.1 : (window.innerWidth < 450) ? -2.6 : -2.2 
  const positiveIconScaleX = 0.9;

  const iconWidth =  15
  const iconHeight =  15

  const mobileDevice = true
  const yAxisMin = -3;
  const yAxisMax = 3;
  const xAxisMin = -3;
  const xAxisMax = 3;
  const quadrantLabelFontSize = 14
  const chartOptions = {
    maintainAspectRatio: false,
    scaleShowVerticalLines:false,
    interaction: {
      mode: 'point', // or 'point' or 'nearest', depending on your preference
    },
  
    // Specify event settings for chart elements
    events: ['click'],
    scales: {
      x: {
        type: "linear",
        position: "bottom",
        border:{
          color:'#101010'
        },
        title: {
          display: true,
          text: conditionalFilters[0].filterName, // Ensure this matches the axis correctly
          color: "#637381",
          padding:{
            top:20
          },
          font: {
            size: mobileDevice ? 12 : 14,
            weight: "bold",
            family: "Arial",
          },
        },
        ticks :{
          display:false
        },
        grid:{
          display:false
        },
        min: xAxisMin,
        max: xAxisMax,
      },
      y: {
        type: "linear",
        position: "left",
        border:{
          color:'#101010'
        },
        title: {
          display: true,
          text: conditionalFilters[1].filterName, // Ensure this matches the axis correctly
          color: "#637381",
          padding:{
            bottom:18,
          },
          font: {
            size: mobileDevice ? 12 : 14,
            weight: "bold",
            family: "Arial",
          },
        },
        ticks :{
          display:false
        },
        grid:{
          display:false
        },
        min: yAxisMin,
        max: yAxisMax,
  
      },
    },
    plugins: {
      annotation: {
        clip:false,
        annotations: { 
          label1: {
            type: 'label',
            xValue: -1.5,
            yValue: 1.5,
            backgroundColor: 'rgba(245,245,245,0)',
            textAlign:'start',
            color:'black',
            content: q2Content,
            font: {
              size: quadrantLabelFontSize,
              weight:'normal',
              lineHeight:1.5
  
            }
          },
          label2: {
            type: 'label',
            xValue: 1.5,
            yValue: 1.5,
            backgroundColor: 'rgba(245,245,245,0)',
            textAlign:'start',
            color:'black',
            content: q1Content,
            font: {
              size: quadrantLabelFontSize,
              weight:'normal',
              lineHeight:1.5
  
            }
          },
          label3: {
            type: 'label',
            xValue: -1.5,
            yValue: -1.5,
            backgroundColor: 'rgba(245,245,245,0)',
            textAlign:'start',
            color:'black',
            content: q3Content,
            font: {
              size: quadrantLabelFontSize,
              weight:'normal',
              lineHeight:1.5
            }
          },
          label4: {
            type: 'label',
            xScaleId:'x',
            yScaleId:'y',
            xValue:1.5,
            yValue:-1.5,
            backgroundColor: 'rgba(245,245,245,0)',
            textAlign:'start',
            color:'black',
            content: q4Content,
            font: {
              size: quadrantLabelFontSize,
              weight:'normal',
              lineHeight:1.5
            }
          },
          label5: {
            type: 'line',
            clip:false,
            borderColor: "#C4CDD5",
            borderWidth: 2,
            borderDash:[6],
            scaleID: 'y',
            value:-3.05,
        },
        label6: {
          type: 'line',
          clip:false,
          borderColor: "#C4CDD5",
          borderWidth: 2,
          borderDash:[6],
          scaleID: 'x',
          value:-3.01,
        },
        label8:{
          type:'label',
          scaleid:'y',
          content:[xAxisStartValue],
          xValue:0,
          yValue:-3.3,
          font: {
            size: quadrantLabelFontSize,
            weight:'bold',
          }
        },
        label9:{
          type:'label',
          scaleid:'x',
          content:['x'],
          xValue:2.97,
          yValue:-3.2,
          font: {
            size: quadrantLabelFontSize,
            weight:'bold',
          }
        },
        label10:{
          type:'label',
          scaleid:'x',
          content:[yAxisStartValue],
          xValue:-3.08,
          yValue:0,
          rotation:-90,
          font: {
            size: quadrantLabelFontSize,
            weight:'bold',
          }
        },
        label11:{
          type:'label',
          scaleid:'x',
          content:['y'],
          xValue:-3.08,
          yValue:2.9,
          font: {
            size: quadrantLabelFontSize,
            weight:'bold',
          }
        },
        label12:{
          type:'label',
          scaleid:'x',
          content:['Top Performing'],
          xValue:1.4,
          yValue:2.5,
          font: {
            size: quadrantLabelFontSize,
            weight:'bold',
          }
        },
        label13:{
          type:'label',
          scaleid:'x',
          content:['Non Performing'],
          xValue:1.4,
          yValue:-0.5,
          font: {
            size: quadrantLabelFontSize,
            weight:'bold',
          }
        },
        label14:{
          type:'label',
          scaleid:'x',
          content:['High Potential'],
          xValue:-1.5,
          yValue:2.5,
          font: {
            size: quadrantLabelFontSize,
            weight:'bold',
          }
        },
        label15:{
          type:'label',
          scaleid:'x',
          content:['Low Discovery'],
          xValue:-1.5,
          yValue:-0.5,
          font: {
            size: quadrantLabelFontSize,
            weight:'bold',
          }
        },
        label16:{
          type:'label',
          scaleid:'x',
          content:highPotential,
          xValue:negativeIconScaleX,
          yValue:2.5,
          width:iconWidth,
          height:iconHeight,
          font: {
            size: quadrantLabelFontSize,
            weight:'bold',
          }
        },
        label17:{
          type:'label',
          scaleid:'x',
          content:lowDiscovery,
          xValue:negativeIconScaleX,
          yValue:-0.5,
          width:iconWidth,
          height:iconHeight,
          font: {
            size: quadrantLabelFontSize,
            weight:'bold',
          }
        },
        label18:{
          type:'label',
          scaleid:'x',
          content:nonperforming,
          xValue:positiveIconScaleX,
          yValue:-0.5,
          width:iconWidth,
          height:iconHeight,
          font: {
            size: quadrantLabelFontSize,
            weight:'bold',
          }
        },
        label19:{
          type:'label',
          scaleid:'x',
          content:topperforming,
          xValue:positiveIconScaleX,
          yValue:2.55,
          width:iconWidth,
          height:iconHeight,
          font: {
            size: quadrantLabelFontSize,
            weight:'bold',
          }
        },
        }
      },
      quadrants: {
        topLeft: "#B3D4FF",
        topRight: "#ABF5D1",
        bottomRight: "#F8CECC",
        bottomLeft: "#FFE599",
      },
      legend:{
        display:false
      },
    }
  };

  const chartData = {
        datasets: [
          {
            label: `${yAxisMetricSelected} - ${xAxisMetricSelected}`,
            data: [],
            backgroundColor: "#fff",
          },
        ],
      };

  const timeDimensions =   [{dimension:"VariantAnalysisTable.obDate",dateRange: [
        formatDate(calendarDate[0].startDate),
        formatDate(calendarDate[0].endDate),
      ]}]

  const getQuadrantData = (queryFilter,updateCompletion) => {
    return new Promise((resolve,reject) => {
      const filtersData = [
        {
          and:[{ member: "VariantAnalysisTable.profileId",
        operator: "equals",
        values: [clientAccountId]},...additonalFilters]
      },
        {
          and:queryFilter
        }
      ]
      cubejsApi
        .load(
          {
            measures: cubeQueryMeasure,
            order: cubeQueryOrder,
            dimensions: ['VariantAnalysisTable.asin'],
            filters: filtersData,
            timeDimensions: timeDimensions,
            limit: 50000,
          },
          {
            headers: {
              "Access-Control-Allow-Origin": true,
            },
          }
        )
        .then((resultSet) => {
         resolve(resultSet?.tablePivot({}))
         updateCompletion()
        })
        .catch((error) => {
          reject(error)
          if (error.message === "Invalid token") {
            setCubeToken(false);
            localStorage.setItem("cubeToken", null);
            refresh();
          }
        });
    })
  }

  const fetchAllQuadrantData = async () => {
    setCompletion(0);
    let completion = 0
    // This function will be used to update the completion status as we're hitting all the API's parallely and not waiting for one to complete
    const updateCompletion = () => {
      completion += 25;
      setCompletion(completion);
    };
    try {  
      const promises = [
        getQuadrantData(quadrant1Filter,updateCompletion),
        getQuadrantData(quadrant2Filter,updateCompletion),
        getQuadrantData(quadrant3Filter,updateCompletion),
        getQuadrantData(quadrant4Filter,updateCompletion),
      ];
      // Hitting all the API's parallely for high performance as done in D2C not waiting for one to complete
      const [q1Data, q2Data, q3Data, q4Data] = await Promise.all(promises);
  
      const q1Summary = generateSummaryData(q1Data);
  
      const q2Summary = generateSummaryData(q2Data);
  
      const q3Summary = generateSummaryData(q3Data);
  
      const q4Summary = generateSummaryData(q4Data);
      setCompletion(100);
      // Now you have all the summary data
      return { q1Summary, q2Summary, q3Summary, q4Summary };
    } catch (error) {
      toast.error('Some unknown error occurred!Please Retry.')
      console.error("Error fetching quadrant data:", error);
    }
  };

  const handleQuadrantDataUpdate = async  (e) => {
    try {
      if(validateInputData(conditionalFilters)) {
      setIsLoading(true)
      const {q1Summary,q2Summary,q3Summary,q4Summary} = await fetchAllQuadrantData()
       setQ1Summary({summary:q1Summary})
       setQ2Summary({summary:q2Summary});
       setQ3Summary({summary:q3Summary});
       setQ4Summary({summary:q4Summary})
       setIsLoading(false)
       setFetchIssue(false)
      } else {
        toast.error('Please select both axis metrics and values to generate quadrant view')
      }
    } catch (error) {
      setFetchIssue(true)
      setIsLoading(false)
      toast.error(JSON.stringify(error))
      if (error.message === "Invalid token") {
        setCubeToken(false);
        localStorage.setItem("cubeToken", null);
        refresh();
      }
    }
   
  }

  
  const handleConditionChange = (actionkey, receivedValue, recievedId) => {
    setConditionalFilters((prev) => {
      return [...prev]?.map((prevFilter) => {
        if (prevFilter.id === recievedId) {
          if(actionkey === 'filterName') { 
            prevFilter[actionkey] = receivedValue;
          }
          if(actionkey === 'value' && !isNaN(receivedValue)) {
            prevFilter[actionkey] = receivedValue;
          }
        }

        return { ...prevFilter };
      });
    });
};

const isDataPresent = () => {
  return Object?.keys(q1Summary?.summary).length > 0 && Object?.keys(q2Summary?.summary).length > 0 && Object?.keys(q3Summary?.summary).length > 0 && Object?.keys(q4Summary?.summary).length > 0
}
const getAppliedFilters = (e,filterKeys,type) => {
  setFilters((prev) => {
    return [...prev]?.map((prevFilter) => {
      if(type === prevFilter?.key) {
        prevFilter.default = e;
      }
      return {...prevFilter}
    })
  })
}




const renderAdditonalFilters = useCallback( () => {
  return (
    <>
     <OptimizerFilters  classNames={"flex-column"} handleDropdown={getAppliedFilters} filtersData={filters}/>
    </>
  )
},[filters])


const isAllQuadrantDataPresent = isDataPresent()



  return (
    <div
      className={
        dashboardSideBarVisibiltyState
          ? "main-content-container-onopen-mode"
          : "main-content-container-oncolapse-mode"
      }
      id="main-box"
    >
   <FiltersModal
        renderMainModalBody={renderAdditonalFilters}
        modalHeading={"Filters"}
        classes={'quadrant-view'}
        show={modalVisible}
        onHide={() => {
          setModalVisible(false);
        }}
      /> 
      <div className="d-flex justify-content-between align-items-center mb-1">
        <div className="mt-1">
      <SectionTitle sectionTitleHeading={"Quadrant View"}></SectionTitle>
      </div>
      <div
              className="select-button-container cursor-pointer-global text-center more-filters-btn select-div align-items-center quadrant-view-filter-btn"
              onClick={() => setModalVisible(true)}
            >
              <div className="selected-opt-show-btn" style={{fontSize:'14px'}}>Filters</div>
            {additonalFilters?.length ?   <div className="show-all-checked-filtering">  <span className="above-two-chip-data-value-container">
            {` + ${additonalFilters?.length}`}
          </span> </div> : null}
              <div className="img-container d-flex align-items-center"
            >
              <img
                className="dropdown-img-for-select"
                style={{height:'70px'}}      
                src={downArrowImage}
                alt={"dropdown"}
              ></img>
            </div>

      </div>
      </div>


      <Card>
        <Card.Body>
          <div className="d-flex justify-content-between">
            <div className="d-flex gap-5">
            <div className="d-flex align-items-center gap-3">
                <CustomDropDown
                  defaultSelected={[]}
                  targettingValue={xAxisMetricSelected}
                  data={XAxisMetrics}
                  title={"Select X Axis Metric"}
                  filterCampaigns={(type, value) => {
                    handleConditionChange('filterName',type?.target?.value,conditionalFilters[0]?.id)
                  }}
                />
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label className="filter-options m-0">
                    X Value
                  </Form.Label>
                  <Form.Control
                    size="lg"
                    type="text"
                    onChange={(e) => {
                      handleConditionChange('value',e?.target?.value?.trim(),conditionalFilters[0]?.id)
                    }}
                    value={conditionalFilters[0]?.value}
                    placeholder="Enter Value"
                  />
                </Form.Group>
              </div>
              <div className="d-flex align-items-center gap-3">
                <CustomDropDown
                  defaultSelected={[]}
                  data={YAxisMetrics}
                  targettingValue={yAxisMetricSelected}
                  title={"Select Y Axis Metric"}
                  filterCampaigns={(type, value) => {
                    handleConditionChange('filterName',type?.target?.value,conditionalFilters[1]?.id)
                  }}
                />
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label className="filter-options m-0">
                    Y Value
                  </Form.Label>
                  <Form.Control
                    size="lg"
                    type="text"
                    onChange={(e) => {
                        handleConditionChange('value',e?.target?.value?.trim(),conditionalFilters[1]?.id)
                    }}
                    value={conditionalFilters[1]?.value}
                    placeholder="Enter Value"
                  />
                </Form.Group>
              </div>
            </div>
            <div className="d-flex align-items-center mt-3">
              <Button variant="primary" size="sm" disabled={loading} className="btn-primary" onClick={handleQuadrantDataUpdate}>
               { loading ? 'Generating...' : 'Generate Quadrant'}
              </Button>
            </div>
          </div>
        </Card.Body>
      </Card>

      <Card className="mt-3">
        <Card.Body>
          <div className="d-flex justify-content-between">
            <p className="quadrant-view-vs-heading">{yAxisMetricSelected !== "Select" &&
            xAxisMetricSelected !== "Select" ? ` ${xAxisMetricSelected} V/S ${yAxisMetricSelected}  Performance` : null} </p>
             <CustomDropDown
                  defaultSelected={[]}
                  data={additionalMetrics}
                  targettingValue={additionalMetric}
                  filterCampaigns={(type, value) => {
                    setAdditionalMetric(type?.target?.value);
                  }}
                />
          </div>
          <div className="d-flex justify-content-center" style={{height:'400px'}}>
          {
            loading 
            ? <>
            <div>
                 <div className="text-center" style={{fontSize:'20px',letterSpacing:'1'}}>
                        {completion <= 10 ? 'Fetching Data...' : completion === 25 ? 'Processing data...': completion === 50 ? `Generating Quadrant`  : completion === 75 ? 'Almost there...' : ''}
                      </div>
                      <div style={{display:"flex",gap:'12px'}}>
                          <div style={{width:'150px'}}> <ProgressBar variant={'info'} size={'medium'} now={completion >= 25 ? 100 : 0} /></div>
                          <div style={{width:'150px'}}> <ProgressBar variant={'info'} size={'medium'} now={completion >= 50 ? 100 : 0} /></div>
                          <div style={{width:'150px'}}> <ProgressBar variant={'info'} size={'medium'} now={completion >= 75 ? 100 : 0} /></div>
                          <div style={{width:'150px'}}> <ProgressBar variant={'info'} size={'medium'} now={completion > 75 ? 100 : 0} /></div>
                      </div>
            </div>
            </> : (isAllQuadrantDataPresent && !loading) ? <>
            <div style={{width:'800px',marginTop:'10px'}}>
                    <Scatter
                      height={200}
                      data={chartData}
                      options={{...chartOptions,
                      }}
                      plugins={quadrants}
                      width={100}
                    />
              </div>
            </> : fetchIssue ? <PageStatus msg={'Some unknown error occurred!Please Retry.'}/> :<PageStatus msg={'Please select metrics and values to generate quadrant view'}/>
          }
          </div>
    
        </Card.Body>
      </Card>
    </div>
  );
};

export default QuadrantView;
