import { summaryMapping } from "../../components/Reports/Components/utils";
import numeral from 'numeral';

export function createQuadrants () {
    const quadrants = {
      id: "quadrants",
      beforeDraw(chart, args, options) {
        const {
          ctx,
          chartArea: { left, top, right, bottom },
        } = chart;
        ctx.fillStyle = '#fff'
    // Calculate midpoints
    const midX = (left + right) / 2;
    const midY = (top + bottom) / 2;
  
    // Fill each quadrant with the specified color
    ctx.save();
    ctx.fillStyle = options.topLeft;
    ctx.fillRect(left, top, midX - left, midY - top);
    ctx.fillStyle = options.topRight;
    ctx.fillRect(midX, top, right - midX, midY - top);
    ctx.fillStyle = options.bottomRight;
    ctx.fillRect(midX, midY, right - midX, bottom - midY);
    ctx.fillStyle = options.bottomLeft;
    ctx.fillRect(left, midY, midX - left, bottom - midY);
    ctx.restore();
  },
    };
    return quadrants
}

export function validateInputData(data) {
  return data?.every((condition) => {
    return (
      condition?.filterName?.toLowerCase() !== "" && condition?.value !== ""
    );
  });
}

export const operatorMapper = {
  x: {
      1: 'gte',
      2: 'lt',
      3: 'lt',
      4: 'gte'
  },
  y: {
      1: 'gte',
      2: 'gte',
      3: 'lt',
      4: 'lt'
  }
};

export const format = '0,0'; // 10,000
export const formatDecimal = '0,0.00'; // 10,000.00

export const metricsMapper = {
  "ACOS":'acos',
  "CPC":"cpc",
  "CTR":"ctr",
  "Spends":"cost",
  "Revenue":"sales14d",
  "Clicks":'clicks',
  "ROAS":"roas",
  "Conv. Rate":"conversion_rate",
  "AOV":"average_order_value",
  "Impressions":"impressions",
  "CPM":"cpm",
  "Orders":"conversions14d"
}

export const getDivideData = (val1, val2) => {
  return parseFloat(val2) !== 0 ? val1 / val2 : 0;
};

export const getCalculatedValue = (key,totals) => {
    if (summaryMapping[key]) {
      const { numeratorKey, denominatorKey, multiplier, symbol, decimal } = summaryMapping[key];
      let numerator = parseFloat(getDivideData(totals[numeratorKey], totals[denominatorKey]));
      const result = isNaN(numerator) ? "-" : (numerator * (multiplier || 1));
      let resultValue = parseFloat(result);
      if(decimal === 0) {
        resultValue = numeral(resultValue).format(format)
      } else {
        resultValue = numeral(resultValue).format(formatDecimal)
      }
      return resultValue + (symbol ? "%" : "")
  } 
  else {
    return isNaN(totals[key]) ? "-" : (numeral(totals[key]).format(format));
  }
};

export function createImage (url) {
  const img = new Image();
  img.src = url;
  img.style.filter = 'grayscale(100%)';
  return img
}

const CATALOG_API_URL = process.env.REACT_APP_CATALOG_API
const ADS_API_URL = process.env.REACT_APP_ADS_API

const CATEGORY_NAMES_API_URL = CATALOG_API_URL + '/client/${clientId}/categories?source=amazon&fields=name'

const CAMPAIGN_NAMES_API_URL = ADS_API_URL + '/client/${clientId}/campaigns?source=amazon&fields=name'

const PRODUCT_SET_API_URL = ADS_API_URL + '/client/${clientId}:product-set?source=amazon'

const PORTFOLIO_API_URL = ADS_API_URL + '/client/${clientId}/portfolios?source=amazon&fields=name'

export const initialFilterConfigs = [
  {
    "name": "Category Names",
    "default": [],
    "multi": true,
    "operator": "IN",
    "options": [],
    "key": "category_name",
    "api": {
      "url": CATEGORY_NAMES_API_URL,
      "key": "name"
    },
    "memberkey":'category',
    "id": "quadrant-view-category-names"
  },
  {
    "name": "Campaign Name",
    "default": [],
    "multi": true,
    "operator": "IN",
    "options": [],
    "key": "campaign_name",
    "api": {
      "url": CAMPAIGN_NAMES_API_URL,
      "key": "name"
    },
    "memberkey":'campaignName',
    "id": "quadrant-view-campaign-names"
  },
  {
    "name": "Campaign Format",
    "default": [],
    "multi": true,
    "operator": "IN",
    "options": ["SD", "SP", "SB(V)", "SB"],
    "key": "campaign_format",
    "id": "quadrant-campaign-format",
    "memberkey":'campaignFormat'
  },
  {
    "name": "Campaign Type",
    "default": [],
    "multi": true,
    "operator": "IN",
    "options": ["Retarget","Prospect"],
    "key": "campaign_type",
    "id": "quadrant-campaign-type",
    "memberkey":'campaignType'
  },
  {
    "name": "Targeting Type",
    "default": [],
    "multi": true,
    "operator": "IN",
    "options": ["G.Keyword","C.Keyword","NB.Keyword","Audience","Automatic","NB.Product","B.Keyword","B.Audience","B.Product"],
    "key": "targeting_type",
    "id": "quadrant-targeting-type",
    "memberkey":'targetingType'
  },
  {
    "name": "Ad Status",
    "default": [],
    "multi": true,
    "operator": "IN",
    "options": ["enabled","paused"],
    "key": "ad_status",
    "id": "quadrant-ad-status",
    "memberkey":'adStatus'
  },
  {
    "name": "Portfolio Name",
    "default": [],
    "multi": true,
    "operator": "IN",
    "options": [],
    "key": "name",
    "api": {
      "url": PORTFOLIO_API_URL,
      "key": "name"
    },
    "memberkey":'portfolioName',
    "id": "quadrant-view-portfolio-names"
  },
  {
    "name": "Product Set",
    "default": [],
    "multi": true,
    "operator": "IN",
    "options": [],
    "key": "product_set",
    "api": {
      "url": PRODUCT_SET_API_URL,
      "key": "product_set"
    },
    "memberkey":'productSet',
    "id": "targeting-product-set"
  }
]

const nodatafoundObj = {
  "acos": "0.00",
  "cpc": "0.00",
  "ctr": "0%",
  "cost": "0",
  "sales14d": "0",
  "clicks": "0",
  "roas": "0.00",
  "conversion_rate": "0%",
  "average_order_value": "0",
  "impressions": "0",
  "cpm": "0.00",
  "conversions14d": "0",
  "total_count": 0,
  "no_data":true
}

export const generateSummaryData = (data) => { 
  const totals = data.reduce((acc, curr) => {
    Object.keys(curr).forEach((key) => {
      if (acc[key] === undefined) {
        acc[key] = 0;
      }
      acc[key] += curr[key];
    });
    return acc;
  }, {});
  const modifiedTotals = {};
  Object.keys(totals).forEach((key) => {
    const modifiedKey = key?.split('.')?.pop();
    modifiedTotals[modifiedKey] = totals[key];
  })
  let calculatedSummary = {};
  Object.keys(totals).forEach((key) => {
    const modifiedKey = key.split('.')?.pop();
    calculatedSummary[modifiedKey] = getCalculatedValue(modifiedKey,modifiedTotals)
  });
  calculatedSummary['total_count'] = data?.length ?? 0;
  // If there is no data then add manually no data found object to show on UI
  if(Object.keys(calculatedSummary).length === 1 && calculatedSummary['total_count'] === 0) { 
    calculatedSummary = nodatafoundObj
  }
  return calculatedSummary;
}

const getOperator = (quadrantNumber, axis) => {
  return operatorMapper[axis][quadrantNumber];
};

export function getAllQuadrantFilters (params) {
  const {xFilterName,yFilterName,xFiltervalue,yFilterValue,additonalFilters} = params
  const xFilterMember = `VariantAnalysisTable.${metricsMapper[xFilterName]}`;
  const yFilterMember = `VariantAnalysisTable.${metricsMapper[yFilterName]}`;
  const quadrant1Filter = [{member:xFilterMember,operator:getOperator(1,'x'),values:[xFiltervalue]},{member:yFilterMember,operator:getOperator(1,'y'),values:[yFilterValue]}]
  const quadrant2Filter = [{member:xFilterMember,operator:getOperator(2,'x'),values:[xFiltervalue]},{member:yFilterMember,operator:getOperator(2,'y'),values:[yFilterValue]}]
  const quadrant3Filter = [{member:xFilterMember,operator:getOperator(3,'x'),values:[xFiltervalue]},{member:yFilterMember,operator:getOperator(3,'y'),values:[yFilterValue]}]
  const quadrant4Filter = [{member:xFilterMember,operator:getOperator(4,'x'),values:[xFiltervalue]},{member:yFilterMember,operator:getOperator(4,'y'),values:[yFilterValue]}]

  return {quadrant1Filter,quadrant2Filter,quadrant3Filter,quadrant4Filter}
}


export const generateContent = (
  totalCount,
  xFilterName,
  xFilterValue,
  yFilterName,
  yFilterValue,
  additionalFilterName,
  additionalFilterValue
) => {
  const content = [
    `No. of Products: ${totalCount ?? 0}`,
    `${xFilterName}: ${xFilterValue}`,
    `${yFilterName}: ${yFilterValue}`,
  ];
  if (additionalFilterName !== "") {
    content.push(`${additionalFilterName}: ${additionalFilterValue}`);
  }
  return content;
};