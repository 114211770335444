import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useGlobalContext } from "../../context/global";
import "./Reports.css";
import { useSearchParams } from "react-router-dom";
import { Stack } from "react-bootstrap";
import Loader from "../loader";
import SectionTitle from "../sectionTitle";
const Reports = (props) => {
  const dashboardSideBarVisibiltyState = useSelector((state) => state.dashboardData.dashboardSideBarVisibiltyState);
  const {selectedPlatform} = useGlobalContext();
  // const {reportData} = props
  const [searchParams, setSearchParams] = useSearchParams();
  const [iframeLoading,setIFrameLoading] = useState(true);
  const url = new URL(window.location.href)?.search
  const urlForIframe = url?.substring(5,url?.length) // Send the url in the form of /reports?url=encodedUrl

  useEffect(() => {
    setIFrameLoading(true);
  }, [selectedPlatform]); // when the selected platform changes, set the iframe loading to true

  const reportHeadPlatform = selectedPlatform === 'flipkart' ? 'Flipkart' : 'Myntra';
  const reportHead = `${reportHeadPlatform} Dashboard`;

  return (
    <div className="main-content-container-oncolapse-mode">
      <Loader visible={iframeLoading}/>
    <Stack  >
      <div className="ps-4">
      <SectionTitle sectionTitleHeading={reportHead} />
      </div>
    <div >
      <div >
      { <iframe
        src={ 
          urlForIframe
        }
        loading="lazy"
        title="report"
        allowFullScreen={true}
        onLoad={() => setIFrameLoading(false)}
        className={dashboardSideBarVisibiltyState?"iframe-style-open":"iframe-style"}
      ></iframe> }
      </div>
    </div>
    </Stack>
    </div>
  );
};

export default Reports;
