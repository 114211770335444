import React, { useEffect, useState } from "react";
import { useDeepCompareMemo } from "use-deep-compare";
import externalLink from "../../../assets/icons/external_link.svg";

import {
  formatTableData,
  measuresFunnal,measuresFunnalDRR,
  measuresPerformance,measuresPerformanceDRR,
  displayDate, format, format1Decimal, formatDecimal,
  metricStrings, getProductWithImage,
} from "./utils";

import numeral from 'numeral';

export const ChildTableRenderer = ({
    resultSet,
    pivotConfig,
    customClasses,
    percentageShow,
    displayType,
    props,
    viewType,
    hiddenKeysParent,
    itemList,
    hideImages,
    drrView
  }) => {
  
    const [hiddenKeys, setHiddenKeys] = useState([]);
    const [selectedChildRows, setSelectedChildRows] = useState([]);
    const handleCellClick = (rowIndex) => {
      setSelectedChildRows((prevSelectedRows) =>
        prevSelectedRows.includes(rowIndex)
          ? prevSelectedRows.filter((row) => row !== rowIndex)
          : [...prevSelectedRows, rowIndex]
      );
    };
    const [tableColumns, dataSourceCalculates] = useDeepCompareMemo(() => {
      const columns = resultSet.tableColumns(pivotConfig);
      return [
        columns,
        formatTableData(columns, resultSet.tablePivot(pivotConfig)),
      ];
    }, [resultSet, pivotConfig]);
    const [dataSource, setDataSource] = useState(dataSourceCalculates);
    const [runQuery, setRunQuery] = useState(false);
    const [tableColumnsCalculations, setTableColumnsCalculations] =
      useState(tableColumns);
      const [totals, setTotals] = useState({});
      useEffect(() => {
      let add = [];
      if (viewType === "Funnel View") {
        if(drrView === 'drr') {
          add = tableColumns.reduce((acc, item, index) => {
            if (measuresPerformanceDRR.includes(item.key.split(".").pop())) {
              acc.push(index);
            }
            return acc;
          }, []);
        } else {
          add = tableColumns.reduce((acc, item, index) => {
            if (measuresPerformance.includes(item.key.split(".").pop())) {
              acc.push(index);
            }
            return acc;
          }, []);
        }
      } else if (viewType === "Performance View") {
        if(drrView === 'drr') {
          add = tableColumns.reduce((acc, item, index) => {
            if (measuresFunnalDRR.includes(item.key.split(".").pop())) {
              acc.push(index);
            }
            return acc;
          }, []);
        } else {
          add = tableColumns.reduce((acc, item, index) => {
            if (measuresFunnal.includes(item.key.split(".").pop())) {
              acc.push(index);
            }
            return acc;
          }, []);
        }
      } else if (viewType === "Custom View") {
        add = [];
      }
      setHiddenKeys((prev) => {
        add.push(
          tableColumns
            .map(function (o) {
              return o.shortTitle;
            })
            .indexOf("month_numeric")
        );
        return add;
      });
    }, [viewType]);
  
    useEffect(() => {
      if (dataSource.length > 0 && Object.keys(totals).length === 0) {
        const newTotals = {}; // Create a new object to avoid directly modifying state
        Object.values(dataSource[0])?.map((totalrow, index) => {
          newTotals[tableColumns[index].key.split(".").pop()] = dataSource
            .reduce((acc, obj) => {
              let key = tableColumns[index].key;
              return acc + parseFloat(obj[key]);
            }, 0)
            .toFixed(0);
        });
        setTotals(newTotals); // Update state with the new totals
      }
    }, [dataSource]);
    
  
    const handleChange = (event, value, type, index) => {
      event.stopPropagation();
      props.handleChange(value, type, index);
    };
  
    let linkedStrings = ["week", "month"];
    var linkedKeys = tableColumns.map(function (o, index) {
      if (linkedStrings.includes(o.shortTitle)) {
        return index;
      }
    });
    linkedKeys = linkedKeys.filter(function (item) {
      return item !== undefined;
    });
  
    var dateKey = tableColumns
      .map(function (o) {
        return o.shortTitle;
      })
      .indexOf("Date");
    useEffect(() => {
      setHiddenKeys((prev) => {
        let data = [...new Set(prev)];
        data.push(
          tableColumns
            .map(function (o) {
              return o.shortTitle;
            })
            .indexOf("month_numeric")
        );
        return data;
      });
    }, [resultSet]);
  
    var imageDataKeys = tableColumns.map(function (o, index) {
      if (o.meta?.image) {
        return index;
      }
    }).filter(function (item) {
      return item !== undefined;
    });
  
    function hideUnhideColumn(value, key) {
      if (value) {
        setHiddenKeys((prev) => {
          let data = [...new Set(prev)];
          const objWithIdIndex = data.indexOf(
            tableColumnsCalculations
              .map(function (o) {
                return o.key.split(".")[1];
              })
              .indexOf(key)
          );
          data.splice(objWithIdIndex, 1);
          return data;
        });
      } else {
        setHiddenKeys((prev) => {
          let data = [...new Set(prev)];
          data.push(
            tableColumnsCalculations
              .map(function (o) {
                return o.key.split(".")[1];
              })
              .indexOf(key)
          );
          return data;
        });
      }
    }
  
    function dragDropColumn(updatedList) {
      const orderedArr = updatedList.map((key) =>
        tableColumnsCalculations.find((obj) => obj.key.split(".")[1] === key)
      );
      const notFoundArr = tableColumnsCalculations.filter(
        (obj) => !updatedList.includes(obj.key.split(".")[1])
      );
  
      const finalArr = [...notFoundArr, ...orderedArr];
      
  
      setTableColumnsCalculations(finalArr);
  
      let arrangedDataSource = dataSource?.map((tr, index1) => {
        let foundData = updatedList.reduce((acc, key) => {
          const entry = Object.entries(tr).find(
            ([k, v]) => k.split(".")[1] === key
          );
          if (entry) {
            acc[entry[0]] = entry[1];
          }
          return acc;
        }, {});
        let notfoundData = Object.entries(tr)
          .filter(([k, v]) => !updatedList.includes(k.split(".")[1]))
          .reduce((acc, [k, v]) => {
            acc[k] = v;
            return acc;
          }, {});
        const mergedObj = { ...notfoundData, ...foundData };
        return mergedObj;
      });
      setDataSource(arrangedDataSource);
    }
  
    useEffect(() => {
      if (viewType === "Custom View") {
        (async () => {
          await dragDropColumn(Object.keys(itemList));
          setRunQuery(true);
        })();
      }
    }, [props]);
  
    useEffect(() => {
      if (viewType === "Custom View" && runQuery) {
        for (const key in itemList) {
          if (!itemList[key].enable) {
            hideUnhideColumn(itemList[key].enable, key);
          }
        }
      }
    }, [dataSource, runQuery]);
  
    var tableBody = (
      <>
        {tableColumns?.length && dataSource?.length ? (
          <>
            {dataSource?.map((tr, index1) => {
              return (
                <React.Fragment key={index1 + 1}>
                  <tr
                    index={index1 + "-row"}
                    onClick={() => handleCellClick(index1 + "childdata")}
                    className={
                      selectedChildRows.includes(index1 + "childdata")
                        ? "highlight cursor-pointer"
                        : "cursor-pointer childRows"
                    }
                  >
                      {Object.entries(tr).map(([datakey, td], columnindex) => (
                      <>
                        {hiddenKeys.includes(columnindex) ? (
                          ""
                        ) : (
                          <td
                            index={columnindex + "-column"}
                            className={
                              columnindex === 0 ? "targetinng-second-column" : ""
                            }
                          >
                            {columnindex === dateKey ? (
                              displayDate(td,'date')
                            ) : linkedKeys.includes(columnindex) ? (
                              <>
                                <div
                                  className="d-flex gap-1 align-items-center justify-content-between"
                                  onClick={(event) =>handleChange( event,td,displayType,columnindex)
                                  }
                                >
                                  <div className="icon-hover">{td}</div>
                                  <img className="tdIcon" src={externalLink} alt="link" />
                                </div>
                              </>
                            ) : imageDataKeys.includes(columnindex) && td !== null  ?
                                  <>{getProductWithImage(td,hideImages)}</> : 
                                  <>                                        
                                  {metricStrings.includes(datakey.split(".").pop()) && datakey.split(".").pop().includes("DRR")  ? numeral(td).format(formatDecimal) : metricStrings.includes(datakey.split(".").pop()) ? numeral(td).format(format) : td}
                                  </>}
                                  { !hideImages && percentageShow ? <>
                                        {metricStrings.includes(datakey.split(".").pop()) ?
                                        td > 0 ? 
                                        " (" + numeral(((td / totals[datakey.split(".").pop()]) * 100)).format(format1Decimal) + "%)"
                                        : "(0%)"
                                        : ""
                                        }
                                        </> : <></>
                                        }
                            
                          </td>
                        )}
                      </>
                    ))}
                  </tr>
                </React.Fragment>
              );
            })}{" "}
          </>
        ) : (
          <div>
            <p>NO DATA FOUND</p>
          </div>
        )}
      </>
    );
    return tableBody;
  };